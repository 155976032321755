<template>
    <div>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">

        <div v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>fetching jobs</p>
            </div>
        </div>
        <div v-if="!fetching && job==null">
            <p>Enquiry {{ $route.params.jobId }} not found</p>
        </div>
        <div v-if="job">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <a-icon type="solution" />
                        <strong> Job </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow class="job-row">
                        <div class="d-flex w-100 justify-content-between">
                            <div>
                            <span class="indicator" ><CBadge color="primary">{{job.jobStatus}}</CBadge></span>
                            </div>
                            <small>{{ job.createdDate | formatDateAgo }}</small>
                        </div>
                        </CRow>
                        <CRow class="job-row">
                            <h5 class="mb-1">{{ job.customerName }}</h5>
                        </CRow>
                        <div v-if="job.jobDescription">
                        <CRow class="job-row">
                            {{ job.jobDescription }}
                        </CRow>
                        </div>
                        <div v-if="job.email">
                        <CRow class="job-row">
                            <a-icon type="mail" /> &nbsp;{{ job.email }}
                        </CRow>
                        </div>
                        <div v-if="job.phone">
                        <CRow class="job-row">
                            <a-icon type="phone" /> &nbsp;{{ job.phone }}
                        </CRow>
                        </div>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <div id="actionsDivId" v-if="showActions" :key="'Actions-' + showActions">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/><strong> Actions </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CButton v-if="mailButton.enabled" :color="mailButton.color" @click="emailResponseModal = true" class="mr-1">
                            <a-icon type="mail" /> {{mailButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="mail" /> {{mailButton.name}}
                        </CButton>

                        <CButton v-if="phoneButton.enabled" :color="phoneButton.color" @click="call()" class="mr-1">
                            <a-icon type="phone" /> {{phoneButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="phone" /> {{phoneButton.name}}
                        </CButton>

                        <CButton v-if="noteButton.enabled" :color="noteButton.color" @click="addNoteModal = true" class="mr-1">
                            <a-icon type="phone" /> {{noteButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="phone" /> {{noteButton.name}}
                        </CButton>

                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            </div>
            <CModal
            :title="emailResponseModalTitle"
            size="lg"
            :show.sync="emailResponseModal"
            >
            <template #footer-wrapper><span/></template>
                    <div class="border-box">
                        <p><b>To:</b> {{job.email}}</p>
                        <p><b>Subject:</b> {{subject}}</p>
                    </div>
                    <div v-if="haveResponseEmailAddress">
                        <div class="border-box">
                            <tiptap v-model="emailResponseText"/>
                        </div>
                    </div>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="sendEmail" class="mr-1">Send</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                        color="secondary"
                        v-on:click="cancelEmailResponse"
                        class="mr-1"
                    >Cancel</CButton>
                        </CCol>
                    </CRow>
            </CModal>
            <CModal
            :title="phoneResponseModalTitle"
            size="lg"
            :show.sync="phoneResponseModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                    <CCol>
                    <div class="email-to">
                        <p><b>Name:</b> {{job.enquirerName}}</p>
                         <p><b>Mobile:</b> {{job.phone}}</p>
                    </div>
                    <CTextarea
                        v-on:input="phoneResponseInput"
                        placeholder="Enter notes..."
                        rows="6"
                    />
                    </CCol>
                    </CRow>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="saveCallNotes">Send</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                        color="secondary"
                        v-on:click="cancelMobileResponse"
                        class="mr-1"
                    >Cancel</CButton>
                        </CCol>
                    </CRow>
            </CModal>
            <div id="communicationsDivId" v-if="showCommunications" :key="'Comms-' + showCommunications">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <a-icon type="mail" />&nbsp;
                        <a-icon type="phone" />
                        <strong> Emails & Phone Calls </strong>
                    </CCardHeader>
                    <CCardBody>
                        <div id='comunications'>
                            <div v-if="fetchingCommunications">
                                <div class="center-align">
                                    <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>fetching communications</p>
                                </div>
                            </div>
                            <CListGroup >
                                <div v-for="(communication) in communications" :key="'CommsList-' + communication.communicationId">
                                    <CommunicationRow 
                                        :communicationId="communication.communicationId" 
                                        :communicationType="communication.communicationType" 
                                        :emailTo="communication.emailTo" 
                                        :emailSubject="communication.emailSubject" 
                                        :email="communication.email" 
                                        :emailBodyHtml="communication.emailBodyHtml" 
                                        :emailBodyText="communication.emailBodyText" 
                                        :message="communication.message" 
                                        :phone="communication.phone" 
                                        :createdDate="communication.createdDate" />
                                </div>
                            </CListGroup>
                        </div>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            </div>
            <div id="notesDivId" v-if="showNotes" :key="'Notes-' + showNotes">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <a-icon type="form" />
                        <strong> Notes </strong>
                    </CCardHeader>
                    <CCardBody>
                        <div id='notes'>
                            <div v-if="fetchingNotes">
                                <p>Fetching notes...</p>
                            </div>
                            <CListGroup >
                                <div v-for="(note) in notes" :key="'NotesList-' + note.noteId">
                                    <NoteRow 
                                        :noteId="note.noteId" 
                                        :noteText="note.noteText" 
                                        :noteType="note.noteType" 
                                        :createdDate="note.createdDate" />
                                </div>
                            </CListGroup>
                        </div>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            </div>
            <div id="addNoteModalDivId">
            <CModal
            title="Add Note"
            size="lg"
            :show.sync="addNoteModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                    <CCol>

                    <CTextarea
                        v-on:input="addNoteInput"
                        placeholder="Enter notes..."
                        rows="6"
                    />
                    </CCol>
                    </CRow>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="saveNote">Save</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                                color="secondary"
                                v-on:click="cancelAddNote"
                                class="mr-1"
                            >Cancel</CButton>
                        </CCol>
                    </CRow>

            </CModal>
            </div>

        </div>

        <div id="errorsDivId" v-if="errors && errors.length">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/><strong> Errors </strong>
                    </CCardHeader>
                    <CCardBody>
                        <div v-for="(error, index) in errors" :key="'Error-' + index">
                        <CListGroupItem>
                            {{error.message}}
                        </CListGroupItem>
                        </div>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
        </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
// import { BIconEnvelope, BIconTelephone } from 'bootstrap-vue'
import Tiptap from '@/components/TipTap.vue'
import Note from '@/components/Note.vue'
import NoteRow from '@/components/NoteRow.vue'
import CommunicationRow from '@/components/CommunicationRow.vue'


export default {

name: 'Job',
components: {
    Note,
    Tiptap,
    NoteRow,
    CommunicationRow
},
  data() {
    return {
        mailButton: {
            name: 'Email',
            color: 'primary',
            enabled: false
        },
        phoneButton: {
            name: 'Call',
            color: 'primary',
            enabled: false
        },
        noteButton: {
            name: 'Add Note',
            color: 'primary',
            enabled: true
        },
      htmlEditor: null,
      haveResponseEmailAddress: null,
      emailResponseText: null,
      job: null,
      communications: null,
      notes: null,
      errors: [],
      fetching: false,
      fetchingCommunications: false,
      fetchingNotes: false,
      contactMatches: null,
      askForContactMatch: false,
      subject: 'Job',
      callNotesText: null,
      noteText: null,
      goAheadNoteText: null,
      showNotes: false,
      showCommunications: false,
      showActions: false,
      emailResponseModal: false,
      phoneResponseModal: false,
      addNoteModal: false,
    //   notesExist: false,
      emailResponseModalTitle: null,
      phoneResponseModalTitle: null,
      matchedContactName: null,
      matchedContactEmail: null,
      matchedContactPhone: null,
      matchedContactGivenNames: null,
      matchedContactSurname: null,
      matchedContactId: null,
      addingNewContactModal: false,
      linkingContactModal: false
    }
  },
  methods: {
    fetchJob () {
        console.log('fetch Job now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        console.log('jobId: ' + this.$route.params.jobId)
        console.log('jobId dummy: ' + 'dummy')
        const url = process.env.VUE_APP_CRM_API_ROOT + '/jobs/' + this.$route.params.jobId;
        console.log('url: ' + url)
        console.log('fetch job')
        this.fetching = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Job, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('fetch job, response: ' + response)
                        this.job = response.data
                        if (typeof this.job.name === 'undefined') {
                            this.emailResponseModalTitle = 'Send email'
                            this.phoneResponseModalTitle = 'Phone call notes'
                        } else {
                            this.emailResponseModalTitle = 'Send email to ' + this.job.name
                            this.phoneResponseModalTitle = 'Notes for phone call to ' + this.job.name
                        }
                        this.setDisplayProperties()

                        this.fetching = false;
                        this.initEmailResponse()
                        this.fetchCommunications()
                        this.fetchNotes()
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('fetch job, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch job, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    fetchCommunications () {
        console.log('fetch communications now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        console.log('jobId: ' + this.$route.params.jobId)
        console.log('jobId dummy: ' + 'dummy')
        const url = process.env.VUE_APP_CRM_API_ROOT + '/communications?jobId=' + this.$route.params.jobId;
        console.log('url: ' + url)
        console.log('fetch communications')
        this.fetchingCommunications = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Communications, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('fetch communications, response: ' + response)
                        this.communications = response.data
                        this.fetchingCommunications = false;
                        this.initEmailResponse()
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                        this.setDisplayProperties()
                    })
                    .then(status => {
                        console.log('fetch communications, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch communications, axios err: ' + err)
                        this.fetchingCommunications = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    fetchNotes () {
        console.log('fetch Notes now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/notes';
        console.log('jobId: ' + this.$route.params.jobId)
        let params = {
            "jobId": this.$route.params.jobId
        }
        console.log('url: ' + url)
        console.log('fetch notes')
        this.fetchingNotes = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Notes, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url, params: params})
                    .then(response => {
                        console.log('fetch notes, response: ' + JSON.stringify(response.data))
                        this.notes = response.data
                        this.fetchingNotes = false
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                        this.setDisplayProperties()
                        // this.notesExist = (response.data.length > 0)
                        // this.showNotes = xxxxxx
                    })
                    .then(status => {
                        console.log('fetch notes, axios status: ' + status)
                        this.fetchingNotes = false
                    })
                    .catch(err => {
                        console.log('fetch notes, axios err: ' + err)
                        this.errors.push(err)
                        this.fetchingNotes = false
                    })
            }
        })
    },
    initEmailResponse() {
        console.log('email response')
        if (typeof this.job.email === 'undefined') {
            this.haveResponseEmailAddress = false
        } else {
            this.haveResponseEmailAddress = true
        }
        this.emailResponseBaseText = '<p>Hi ' + this.job.enquirerName + ',</p>'
        if (this.job.jobType == "Web") {
            this.emailResponseBaseText = this.emailResponseBaseText + '<p>Thanks for your enquiry from my Web Page.</p>'
            this.subject = 'Enrich Interiors Web Enquiry'
        } else {
            this.emailResponseBaseText = this.emailResponseBaseText + '<p>Thanks for your enquiry.</p>'
            this.subject = 'Enrich Interiors Enquiry'
        }
        if (this.job.jobMessage !=='') {

            this.emailResponseBaseText = this.emailResponseBaseText + '<blockquote>' + this.job.jobMessage + '</blockquote>'
            this.emailResponseBaseText = this.emailResponseBaseText + '<p></p>'
        }
        this.emailResponseText = this.emailResponseBaseText
        // this.emailResponseBaseText = '<p>Hi ' + '</p>'
        // this.htmlEditor = new Editor({
        //     content: this.emailResponseBaseText,
        //     extensions: [
        //         StarterKit,
        //     ],
        // })
        console.log('email response initialised')
    },
    sendEmail(event) {
        console.log('in sendEmail')
        this.emailResponseModal = false
        // var editorJson = this.htmlEditor.getJSON()
        // var editorJson = this.emailResponseText.getJSON()
        // console.log('htmlEditor JSON: ' + JSON.stringify(editorJson))
        //var editorObject = JSON.parse(editorJson)
        //console.log('email text: ' + editorObject.content)
        // var editorHtml = this.htmlEditor.getHTML()
        var editorHtml = this.emailResponseText
        console.log('htmlEditor html: ' + editorHtml)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/communications';
        console.log('post email url: ' + url);
        let communication = {
            "emailTo": this.job.email,
            "emailFrom": "support@enrichinteriors.com.au",
            "emailSubject": this.subject,
            "communicationType": "Email",
            "name": this.job.enquirerName,
            "emailBodyHtml": editorHtml,
            "emailBodyText": this.convertHtmlToText(editorHtml),
            "jobId": this.$route.params.jobId
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Job - send email, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: communication, url: url})
                    .then(response => {
                        console.log('Post email, response: ' + JSON.stringify(response))
                        // this.job = response.data
                        delete this.$http.defaults.headers.common['Authorization'];
                    })
                    .then(status => {
                        console.log('post email, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('post email, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    call() {
        console.log('in job call')
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.open("tel:" + this.opportunity.phone);
        }
        this.phoneResponseModal = true
        // this.$refs["phone-response-modal-ref"].show();   
    },
    convertHtmlToText(html) {
        // Create a new div element
        var tempDivElement = document.createElement("div");

        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;

        // Retrieve the text property of the element 
        let text = tempDivElement.textContent || tempDivElement.innerText || ""
        console.log('CONVERTED to: ' + text)
        return text.replace('null',' ');
    },
    cancelMobileResponse() {
        this.phoneResponseModal = false
    },
    cancelEmailResponse() {
        this.emailResponseModal = false
    },
    cancelAddNote() {
        this.addNoteModal = false
    },
    saveCallNotes() {
        console.log('in saveCallNotes, callNotesText: ' + this.callNotesText)
        this.phoneResponseModal = false
        const url = process.env.VUE_APP_CRM_API_ROOT + '/communications';
        console.log('post email url: ' + url);
        let communication = {
            "phone": this.job.phone,
            "name": this.job.enquirerName,
            "communicationType": "Phone",
            "message": this.callNotesText,
            "jobId": '' + this.$route.params.jobId
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Job - phone notes, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: communication, url: url})
                    .then(response => {
                        console.log('Post phone notes, response: ' + JSON.stringify(response))
                        this.callNotesText = null
                        this.fetchCommunications()
                        // this.job = response.data
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('post phone notes, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('post phone notes, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    patchJob(contactId, jobStatus) {
        console.log('patchJob, contactId: ' + contactId + ', jobStatus: ' + jobStatus)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/opportunities/' + this.job.jobId;
        let job = {
            "contactId": contactId,
            "jobStatus": jobStatus
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'patch', data: job, url: url})
                    .then(response => {
                        console.log('patchJob, response: ' + JSON.stringify(response.data))
                        // this.job = response.data
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                        console.log('patchJob, set job.contactId to: ' + contactId)
                    })
                    .then(status => {
                        console.log('patchJob, axios status: ' + status)
                        this.status = status;
                        this.linkingContactModal = false
                    })
                    .catch(err => {
                        console.log('patchJob, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)
                        this.linkingContactModal = false
                    })
            }
        })
    },
    saveNote() {
        console.log('save note: ' + this.noteText)
        this.postNote(this.noteText, 'General')
        // this.fetchNotes()
        this.setDisplayProperties()
        this.addNoteModal = false
    },
    postNote(noteText, noteType) {
        console.log('post note: ' + this.noteText)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/notes';
        console.log('post notes url: ' + url);
        let note = {
            "noteText": noteText,
            "noteType": noteType,
            "jobId": this.$route.params.jobId
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('postNote, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: note, url: url})
                    .then(response => {
                        console.log('postNote, response: ' + JSON.stringify(response))
                        // this.job = response.data
                        delete this.$http.defaults.headers.common['Authorization'];
                        this.fetchNotes()
                    })
                    .then(status => {
                        console.log('postNote, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('postNote, axios err: ' + err)
                        this.errors.push(err)

                    })
            }
        })
    },
    phoneResponseInput(event) {
        console.log('phoneResponseInput: ' + event)
        this.callNotesText = event
    },
    addNoteInput(event) {
        console.log('addNotes: ' + event)
        this.noteText = event
    },
    hide(event) {
        console.log('hide event: ' + event)
    },
    addUrlParam(url, paramName, paramValue) {

        if (typeof url === 'undefined') {
            console.error('invalid url for addUrlParam')
            return
        }

        if (typeof paramName === 'undefined') {
            console.error('invalid paramName for addUrlParam')
            return
        }

        if (typeof paramValue === 'undefined') {
            console.error('invalid paramValue for addUrlParam')
            return
        }

        if (url.indexOf("?") > 0) {
            return encodeURI(url + '&' + paramName + '=' + paramValue) //.replace("+", "%2b")
        }

        return encodeURI(url + '?' + paramName + '=' + paramValue) //.replace("+", "%2b")
    },
    setDisplayProperties() {

        if (this.job.contactId == null && this.contactMatches != null && this.contactMatches.length > 0) {
            this.askForContactMatch = true
            this.showCommunications = false
            this.showActions = false
            this.showNotes = false
        } else {
            this.askForContactMatch = false
            this.showCommunications = true
            this.showActions = true
            if (this.notes != null && this.notes.length > 0) {
                this.showNotes = true
            } else {
                this.showNotes = false
            }
        }
        
        if (this.job.email) {
            this.mailButton.enabled = true
        } else {
            this.mailButton.enabled = false
        }
        if (this.job.phone) {
            this.phoneButton.enabled = true
        } else {
            this.phoneButton.enabled = false
        }
        
    }
  },

  // Fetches posts when the component is created.
  mounted() {
        console.log('call fetch for Job!!!')
        this.fetchJob()
        console.log('Job:created')
  }
//   ,
//   beforeRouteUpdate (to, from, next) {
//         console.log('call fetch!!!')
//         this.fetchJob()
//         console.log('Job:beforeRouteUpdate')
//         this.$refs.topLoader.done();
//         console.log(to);//not even this
//         next();
//   }
}

</script>

<style>
.indicator {
    margin-right: 10px;
    font-size: large;
}

.job-row {
    padding-bottom: 5px;
}

</style>
